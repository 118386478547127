import React from 'react';
import man from '../assets/images/about/man.png';
import man2 from '../assets/images/about/man-m.png';
import meaningful from '../assets/images/about/meaningful.png';
import maven from '../assets/images/about/maven.png';
import conversation from '../assets/images/about/conversation.png';
import forbes from '../assets/images/about/forbes.png';
import DownloadAppSection from '../components/CommonSections/DownloadAppSection';
import {Link} from 'gatsby';

const AboutJinesh = () => {
  return (
    <div>
      <div className="about_jinesh">
        <div className="banner text-center">
          <div className="container">
            <h2>About Jinesh</h2>
            <p>What’s driving our CEO</p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <p className="mt-5 mt-lg-0" style={{fontWeight: '800'}}>
                Jinesh Vohra{' '}
              </p>
              <p>
                Jinesh is the Co-Founder and CEO of Sprive, the UK’s leading independent mortgage
                app on a mission to help homeowners achieve mortgage freedom faster and save on
                interest. <br/> <br/>
                Inspired by his own personal frustrations while paying off his own mortgage, he
                launched Sprive in 2021. From making overpayments on his mortgage to try and pay it
                off as quickly as possible, Jinesh quickly realised that it was far from an easy and
                straightforward process.
              </p>
            </div>
            <div className="col-12 col-lg-6 p-0 p-lg-auto">
              <img className="d-none d-lg-block w-100 img-fluid" src={man} alt=""/>
              <img className="d-block d-lg-none w-100 img-fluid" src={man2} alt=""/>
            </div>
          </div>
          <p>
            After successfully becoming mortgage-free himself, he found the freedom to leave his job
            and pursue his dream of assisting others on their journey towards being debt free. Read
            more about Jinesh’s mortgage journey and the birth of Sprive{' '}
            <Link
              target="_blank"
              to="https://sprive.com/blog/sprives-ceo-shares-his-mortgage-journey/"
            >
              here.
            </Link>{' '}
            <br/>
            <br/> Prior to founding Sprive, Jinesh worked for 14 years at Goldman Sachs, where he
            was a Director, and holds a Bachelor of Science Honors degree in Economics from the
            University of Warwick.
          </p>
        </div>
      </div>
      <div className="featured">
        <div className="container">
          <h3>As featured on</h3>
          <div className="row">
            <div
              style={{paddingLeft: '12px', paddingRight: '12px'}}
              className="col-12 col-md-6 col-lg-3 mb-4"
            >
              <Link
                target="_blank"
                to="https://meaningfulmoney.tv/2022/02/09/mortgage-free-faster-with-sprive/"
              >
                <div className="box">
                  <div className="img_box">
                    <img src={meaningful} alt=""/>
                  </div>
                  <p>PODCAST</p>
                  <h5>
                    Meaningful <br/> Money
                  </h5>
                </div>
              </Link>
            </div>
            <div
              style={{paddingLeft: '12px', paddingRight: '12px'}}
              className="col-12 col-md-6 col-lg-3 mb-4"
            >
              <Link target="_blank" to="https://www.mavenadviser.com/podcast-9/jinesh-vohra">
                <div className="box">
                  <div className="img_box">
                    <img src={maven} alt=""/>
                  </div>
                  <p>PODCAST</p>
                  <h5>
                    Maven <br/> Money
                  </h5>
                </div>
              </Link>
            </div>
            <div
              style={{paddingLeft: '12px', paddingRight: '12px'}}
              className="col-12 col-md-6 col-lg-3 mb-4"
            >
              <Link
                target="_blank"
                to="https://theconversationofmoneypodcast.captivate.fm/episode/how-to-become-mortgage-free-faster-with-sprive"
              >
                <div className="box">
                  <div className="img_box">
                    <img src={conversation} alt=""/>
                  </div>
                  <p>PODCAST</p>
                  <h5>
                    Conversation <br/> of Money
                  </h5>
                </div>
              </Link>
            </div>
            <div
              style={{paddingLeft: '12px', paddingRight: '12px'}}
              className="col-12 col-md-6 col-lg-3 mb-4"
            >
              <Link
                target="_blank"
                to="https://www.forbes.com/uk/advisor/personal-finance/2021/04/19/quickfire-questions-jinesh-vohra/"
              >
                <div className="box">
                  <div className="img_box">
                    <img src={forbes} alt=""/>
                  </div>
                  <p>INTERVIEW</p>
                  <h5>
                    Forbes <br/> Advisor (UK)
                  </h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <DownloadAppSection/>
    </div>
  );
};

export default AboutJinesh;
